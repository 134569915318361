type QuoteProps = {
  className?: string
}
const Quote = ({ className }: QuoteProps) => {
  return (
    <svg className={className} width="85" height="60" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 60">
      <path fillRule="evenodd" clipRule="evenodd" d="M83.4647 43.2275C83.4647 52.9105 75.1699 60 64.2832 60c-11.5774 0-19.181-8.473-19.181-21.268 0-14.6973 5.7024-30.08657 14.3425-38.732h24.7111C73.7876 7.08906 64.9744 17.6368 61.0001 26.8011c1.3823-.3456 2.7651-.5188 4.3201-.5188 10.3682 0 18.1445 7.2622 18.1445 16.9452ZM15.8979 26.8011c1.3827-.3456 2.7651-.5188 4.3205-.5188 10.1955 0 17.9714 7.2622 17.9714 16.9452S30.0681 60 19.1814 60C7.60318 60 0 51.527 0 38.732 0 24.0347 5.70239 8.64543 14.3429 0H38.881C28.5131 7.08906 19.8726 17.4641 15.8979 26.8011Z" fill="#B2D5D3"/>
    </svg>
  )
}

export default Quote
